<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs4 sm4>
                <h3>
                  <v-icon>dynamic_feed</v-icon>
                  Select from fields
                </h3>
                <br />
                <v-select
                  :items="batches"
                  persistent-hint
                  hint="Select from which batch you want to migrate your routines from"
                  :disabled="!batches.length"
                  class="pa-0"
                  label="Migrate From Batch"
                  outlined
                  dense
                  v-model="migrate.from.batchId"
                />
                <br />

                <v-layout row wrap>
                  <v-flex xs6>
                    <v-select
                      :items="migrate.from.grades"
                      :disabled="!migrate.from.grades.length"
                      class="pa-0"
                      label="From Grade"
                      outlined
                      dense
                      v-model="migrate.from.gradeId"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :items="migrate.from.sections"
                      :disabled="!migrate.from.sections.length"
                      class="pa-0"
                      label="From Section"
                      outlined
                      dense
                      v-model="migrate.from.sectionId"
                    />
                  </v-flex>
                </v-layout>

                <v-select
                  :loading="migrate.from.subjectLoading"
                  :items="migrate.from.subjects"
                  persistent-hint
                  :disabled="!migrate.from.subjects.length"
                  class="pa-0"
                  label="From Subject"
                  outlined
                  dense
                  v-model="migrate.from.subjectId"
                />
              </v-flex>

              <v-flex xs4 sm4 offset-xs2>
                <h3>
                  <v-icon>how_to_vote</v-icon>
                  Suggested to fields
                </h3>
                <br />
                <v-select
                  :items="batches"
                  persistent-hint
                  outlined
                  dense
                  hint="Select batch where you would like to migrate selected routines"
                  :disabled="!batches.length || true"
                  class="pa-0"
                  label="Migrate to batch"
                  v-model="migrate.to.batchId"
                />

                <br />
                <v-layout row wrap>
                  <v-flex xs6>
                    <v-select
                      :items="migrate.to.grades"
                      :disabled="!migrate.to.grades.length || true"
                      class="pa-0"
                      label="To Grade"
                      outlined
                      dense
                      v-model="migrate.to.gradeId"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :items="migrate.to.sections"
                      :disabled="!migrate.to.sections.length || true"
                      class="pa-0"
                      label="To Section"
                      outlined
                      dense
                      v-model="migrate.to.sectionId"
                    />
                  </v-flex>
                </v-layout>
                <v-select
                  :loading="migrate.to.subjectLoading"
                  :items="migrate.to.subjects"
                  persistent-hint
                  :disabled="true"
                  class="pa-0"
                  label="To Subject"
                  outlined
                  dense
                  v-model="migrate.to.subjectId"
                />
              </v-flex>
            </v-layout>
          </v-container>
          <div>
            <div
              class="pa-3 subject_list_group"
              v-if="successfullMigrations.length"
            >
              <p style="color:#666;margin-bottom: 2px;font-weight: bold;">
                &nbsp;Migrated Subjects
              </p>
              <span v-for="(list, key) in successfullMigrations" :key="key">
                <v-chip style="color:white;" color="primary">
                  {{ list.subject.name }}
                </v-chip>
              </span>
            </div>

            <v-data-table
              hide-default-footer
              :headers="headers"
              :loading="migrate.from.loading"
              :options.sync="pagination"
              :items="migrate.from.routines"
            >
              <template v-slot:item="{ index, item }">
                <tr>
                  <td class="text-xs-left">
                    {{ item.start_time }} - {{ item.end_time }}
                  </td>
                  <td class="text-xs-left">
                    {{ item.routine_type | capitalize }}
                  </td>
                  <td class="text-xs-left">
                    {{ item.routine_day | capitalize }}
                  </td>
                  <td class="text-xs-left">
                    {{
                      item.subject
                        ? item.subject.name
                          ? item.subject.name
                          : ""
                        : ""
                    }}
                  </td>
                  <td class="text-xs-center">{{ item.period }}</td>
                  <td class="text-xs-left">
                    {{ item.shift | capitalize }}
                  </td>
                  <td class="text-xs-left">
                    {{
                      item.teacher
                        ? item.teacher.name
                          ? item.teacher.name
                          : ""
                        : ""
                    }}
                  </td>
                  <td class="text-xs-right">
                    <edit-button
                      permission="subject-routine-update"
                      @agree="updateRoutine(item)"
                    />
                    <delete-button
                      permission="subject-routine-delete"
                      @agree="form.delete(item.id)"
                    />
                  </td>
                </tr>
              </template>
            </v-data-table>

            <v-btn
              @click="migrateNow"
              block
              :disabled="
                !migrate.from.routines.length ||
                  !migrate.to.subjectId ||
                  !migrate.to.sectionId ||
                  !migrate.to.gradeId
              "
              large
              color="primary"
            >
              Migrate Routine
            </v-btn>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { filterObjectFromArray, getThForm } from "../../../../library/helpers";

export default {
  data: () => ({
    migrate: {
      from: {
        batchId: "",
        gradeId: "",
        sectionId: "",
        grades: [],
        sections: [],
        subjects: [],
        subjectId: "",
        routines: [],
        loading: false,
        subjectLoading: false,
      },
      to: {
        batchId: "",
        gradeId: "",
        sectionId: "",
        grades: [],
        sections: [],
        subjects: [],
        subjectId: "",
        subjectLoading: false,
      },
    },
    successfullMigrations: [],
    batches: [],
    headers: [
      {
        text: "Time",
        align: "left",
        value: "start_time",
        width: 125,
        sortable: false,
      },
      { text: "Type", align: "left", value: "routine_type", sortable: false },
      { text: "Day", align: "left", value: "routine_day", sortable: false },
      { text: "Subject", align: "left", value: "subject", sortable: false },
      { text: "Period", align: "center", value: "period", sortable: false },
      { text: "Shift", align: "left", value: "shift", sortable: false },
      { text: "Teacher", align: "left", value: "teacher", sortable: false },
      { text: "Action", align: "right", sortable: false, width: 200 },
    ],
    pagination: {
      rowsPerPage: 200,
    },
  }),

  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    this.getBatches();
  },

  watch: {
    "migrate.from.batchId": function(value) {
      this.migrate.to.batchId = "";
      this.migrate.to.gradeId = "";
      this.migrate.to.sectionId = "";
      this.migrate.to.subjectId = "";
      this.getGrades(value);
      // this.isReadyToEvaluate();
    },
    "migrate.from.gradeId": function(value) {
      if (value) {
        this.getSection(value);
        this.getSubjects();
        this.isReadyToEvaluate();
      }
      this.successfullMigrations = [];
      this.migrate.from.routines = [];
    },
    "migrate.from.sectionId": function(value) {
      if (value) this.isReadyToEvaluate();
      this.successfullMigrations = [];
      this.migrate.from.routines = [];
    },
    "migrate.from.subjectId": function(value) {
      if (value) this.isReadyToEvaluate();
      this.migrate.from.routines = [];
    },

    "migrate.to.gradeId": function(value) {
      if (value) this.getSection(value, "to");
    },
  },

  methods: {
    filterObjectFromArray,
    getThForm,
    getBatches() {
      this.$rest.get("/api/batch?slim=true&rowsPerPage=25").then(({ data }) => {
        this.batches = data.data.map((item, key) => {
          return { value: item.id, text: item.name, is_hs: item.is_hs };
        });
      });
    },

    getGrades(selectedBatchId, type = "from") {
      this.gradeLoading = true;
      this.migrate[type].sectionId = "";
      this.migrate[type].gradeId = "";
      return new Promise((resolve, reject) => {
        this.$rest
          .get(
            `/api/grades?rowsPerPage=25&orderBy=rank&descending=false&slim=true&batchId=${selectedBatchId}`
          )
          .then(({ data }) => {
            this.migrate[type].grades = data.data.map((item) => {
              return {
                value: item.id,
                text: item.name,
                sections: item.sections,
              };
            });
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getSection(gradeId, type = "from") {
      if (!gradeId) return [];

      // this.migrate[type].sectionId = '';
      this.migrate[type].grades.map((item) => {
        if (item.value === gradeId) {
          this.migrate[type].sections = item.sections.map(function(section) {
            return { value: section.id, text: section.name };
          });
        }
      });
      return this.migrate[type].sections;
    },

    getSubjects(type = "from") {
      this.migrate[type].subjectId = "";
      this.migrate[type].subjectLoading = true;

      return new Promise((resolve, reject) => {
        this.$rest
          .get(
            "/api/subject?rowsPerPage=25&grade=" +
              this.migrate[type].gradeId +
              "&batchId=" +
              this.migrate[type].batchId
          )
          .then(({ data }) => {
            this.migrate[type].subjects = data.data.map((item) => {
              return { value: item.id, text: item.name };
            });
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            this.migrate[type].subjectLoading = false;
          });
      });
    },

    isReadyToEvaluate() {
      if (
        this.migrate.from.sectionId &&
        this.migrate.from.gradeId &&
        this.migrate.from.batchId &&
        this.migrate.from.subjectId
      ) {
        //To Batch Evaluation
        try {
          const selectedFromBatch = this.selectedBatch();
          const newBatches = this.batches.slice(
            this.batches.indexOf(selectedFromBatch) + 1
          );
          newBatches
            .slice()
            .reverse()
            .map((toBatch) => {
              if (
                toBatch.is_hs &&
                selectedFromBatch.is_hs &&
                toBatch.value !== this.migrate.from.batchId
              ) {
                this.migrate.to.batchId = toBatch.value;
                new Error("Done");
              }
              if (
                !toBatch.is_hs &&
                !selectedFromBatch.is_hs &&
                toBatch.value !== this.migrate.from.batchId
              ) {
                this.migrate.to.batchId = toBatch.value;
                new Error("Done");
              }
            });
        } catch (e) {
          console.log(e);
        }

        //To Grade and Section and subject Evaluation
        const selectedFromGrade = this.selectedGrade();
        const selectedFromSection = this.selectedSection();
        if (this.migrate.to.batchId) {
          //grade
          this.getGrades(this.migrate.to.batchId, "to").then((grades) => {
            grades.data.map((grade) => {
              if (grade.name === selectedFromGrade.text) {
                this.migrate.to.gradeId = grade.id;
                //section
                grade.sections.map((sec) => {
                  if (sec.name === selectedFromSection.text) {
                    this.migrate.to.sectionId = sec.id;
                  }
                });
              }
            });

            //subject
            const selectedFromSubject = this.selectedSubject();
            if (this.migrate.to.gradeId)
              this.getSubjects("to").then((subjects) => {
                subjects.data.map((subject) => {
                  if (subject.name === selectedFromSubject.text) {
                    this.migrate.to.subjectId = subject.id;
                  }
                });
              });

            this.migratedRoutine();
          });
        }

        //Finally Fetch routine
        this.getRoutine();
      }
    },

    selectedSection(type = "from") {
      let output = {};
      try {
        this.migrate[type].sections.map((item) => {
          if (item.value === this.migrate[type].sectionId) {
            output = item;
            new Error("Done");
          }
        });
      } catch (e) {}
      return output;
    },

    selectedGrade(type = "from") {
      let output = {};
      try {
        this.migrate[type].grades.map((item) => {
          if (item.value === this.migrate[type].gradeId) {
            output = item;
            new Error("Done");
          }
        });
      } catch (e) {
        console.log(e);
      }
      return output;
    },

    selectedSubject(type = "from") {
      let output = {};
      try {
        this.migrate[type].subjects.map((item) => {
          if (item.value === this.migrate[type].subjectId) {
            output = item;
            new Error("Done");
          }
        });
      } catch (e) {
        console.log(e);
      }
      return output;
    },

    selectedBatch(type = "from") {
      let output = {};
      try {
        this.batches.map((item) => {
          if (item.value === this.migrate[type].batchId) {
            output = item;
            new Error("Done");
          }
        });
      } catch (e) {
        console.log(e);
      }
      return output;
    },

    getRoutine(type = "from") {
      const { sectionId, batchId, gradeId, subjectId } = this.migrate[type];
      this.migrate[type].loading = true;
      this.$rest
        .get(
          `/api/routine?rowsPerPage=100&sectionId=${sectionId}&subjectId=${subjectId}`
        )
        .then(({ data }) => {
          this.migrate[type].routines = data.data;
        })
        .finally(() => {
          this.migrate[type].loading = false;
        });
    },

    migrateNow() {
      const { sectionId, gradeId, subjectId } = this.migrate.from;
      const migrate = {
        from: {
          sectionId: this.migrate.from.sectionId,
          gradeId: this.migrate.from.gradeId,
          subjectId: this.migrate.from.subjectId,
        },
        to: {
          sectionId: this.migrate.to.sectionId,
          gradeId: this.migrate.to.gradeId,
          subjectId: this.migrate.to.subjectId,
        },
      };
      this.$rest
        .post("/api/migrate-routine", { ...migrate })
        .then((res) => {
          this.$events.fire("notification", {
            message: "Migration was successful",
            status: "success",
          });
          this.migratedRoutine();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },

    migratedRoutine() {
      this.$rest
        .get(`/api/migrated-routine?sectionId=${this.migrate.to.sectionId}`)
        .then(({ data }) => {
          this.successfullMigrations = data;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.inputTime {
  border: solid 1px #777;
  width: 65px;
  text-align: center;
  height: 32px;
}

.teacherInfo {
  position: absolute;
  margin-top: -21px;
  font-size: 12px;
  color: #777;
}

.inputTime:disabled {
  cursor: not-allowed;
  background: #99999921;
}

.theme--light.v-table tbody td {
  border: 1px solid rgba(0, 0, 0, 0.22);
}

.cellHover {
  cursor: pointer;
}

table td div p {
  padding-bottom: 0px;
  margin-bottom: 7px;
}

table td div {
  margin-top: 5px;
}

table td div:nth-child(2n + 1) {
  border-bottom: 1px solid #ccc;
}

table td div:last-child {
  border-bottom: none;
}

.subject_list_group {
  .v-chip,
  .v-chip .v-chip__content {
    border-radius: 2px;
  }
}
</style>
